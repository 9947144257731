import { asUrl, buildThing, createThing, getSolidDataset, getSourceUrl, getThing, hasResourceInfo, removeThing, saveSolidDatasetAt, setThing, UrlString } from "@inrupt/solid-client"
import { useSession } from "@inrupt/solid-ui-react"
import { useContext, useEffect, useState } from "react"
import { SelectionContext } from "../../context/SelectionContext"
import { Selection } from "../../types/Selection"
import { SelectionEditor } from "./SelectionEditor"
import { SelectionHull } from "./SelectionHull"
import { RDF, DCTERMS } from "@inrupt/vocab-common-rdf"
import { v4 } from "uuid"
import { crm } from "../../helpers/namespaces"
import { EventEmitter } from "../../helpers/EventEmitter"
import { WorkspaceContext } from "../../context/ScoreSurfaceContext"
import { AnalysisContext } from "../../context/AnalysisContext"
import { createPortal } from "react-dom"

/**
 * Manages the selections of a particular analytical layer
 * by displaying hulls and an editor for the attribute assignments
 * once a hull has been clicked.
 */
export const SelectionContainer = ({ selections, active }: { selections: Selection[], active: boolean }) => {
    const { session } = useSession()
    const { analysisThing, analysisDataset: dataset, updateDataset, editable, availableE13s: e13s } = useContext(AnalysisContext)
    const { workUrl, scoreIsReady, activeSelection, setActiveSelection, setActiveLayer, selectionPanel } = useContext(WorkspaceContext)

    const [hullContainer, setHullContainer] = useState<SVGGElement>()
    const [secondaryActiveSelection, setSecondaryActiveSelection] = useState<Selection>()

    useEffect(() => {
        if (dataset &&
            hasResourceInfo(dataset) &&
            editable &&
            active) {
            EventEmitter.subscribe('start-new-selection', startNewSelection)
            EventEmitter.subscribe('remove-from-active-selection', removeFromActiveSelection)
            EventEmitter.subscribe('expand-active-selection', expandActiveSelection)
        }
    }, [dataset, editable, active])

    useEffect(() => {
        if (!scoreIsReady) return

        setTimeout(() => {
            // Add a <g> element to the SVG generated by verovio
            // which serves as a container for the overlays
            // that outline selections in the score
            const svg = document.querySelector('.verovio svg')
            if (!svg) {
                console.log('no SVG element found')
                return
            }

            // Does the SVG have a hull container already?
            const existingContainer = svg.querySelector('#hull-container')
            if (existingContainer) {
                setHullContainer(existingContainer as SVGGElement)
                return
            }

            // otherwise create a new container and insert it 
            // below the staves, so that notes etc. remain selectable
            const g = document.createElementNS('http://www.w3.org/2000/svg', 'g')
            g.setAttribute('id', 'hull-container')
            const firstSystem = svg.querySelector('.system')
            if (!firstSystem) return

            firstSystem.parentElement?.insertBefore(g, firstSystem)
            setHullContainer(g)
        }, 1000)
    }, [scoreIsReady])

    const saveSelection = async (selection: Selection) => {
        if (!selection) return

        if (!dataset || !hasResourceInfo(dataset) || !editable) {
            console.warn('No dataset found to save the new work to.')
            return
        }

        // saves the given selection in the POD
        console.log('url=', selection.url)
        const selectionThing = buildThing(getThing(dataset, selection.url) || createThing({
            url: selection.url
        }))
            .addUrl(RDF.type, crm('E90_Symbolic_Object'))
            .addDate(DCTERMS.created, new Date(Date.now()))
            .addUrl(crm('P106i_forms_part_of'), workUrl)

        console.log('selectionThing', asUrl(selectionThing.build()))

        selection.refs.forEach(ref => {
            selectionThing.addUrl(crm('P106_is_composed_of'), `${workUrl}#${ref}`)
        })

        if (!analysisThing) return

        const updatedAnalysis = buildThing(analysisThing)
            .addUrl(crm('P16_used_specific_object'), selection.url)

        let modifiedDataset = setThing(dataset, selectionThing.build())
        modifiedDataset = setThing(modifiedDataset, updatedAnalysis.build())

        const savedDataset = await saveSolidDatasetAt(getSourceUrl(modifiedDataset), modifiedDataset, { fetch: session.fetch as any });
        updateDataset(await getSolidDataset(getSourceUrl(savedDataset), { fetch: session.fetch as any }))
    }

    const startNewSelection = (ref: string) => {
        if (!active) return 

        console.log('start new selection')
        if (!session.info.isLoggedIn || !editable) {
            console.log('Cannot create a new selection without being logged in')
            return
        }

        if (!dataset || !hasResourceInfo(dataset)) {
            console.log('Cannot create selection without having a dataset defined.')
            return
        }

        // TODO generate url instead
        const newSelection: Selection = {
            url: `${getSourceUrl(dataset)}#${v4()}`,
            refs: [ref]
        }
        setActiveSelection(newSelection)
        saveSelection(newSelection)
    }

    const expandActiveSelection = (ref: string) => {
        if (!active) return

        if (!editable || !activeSelection) return

        const expandedSelection = {
            url: activeSelection.url,
            refs: [...activeSelection.refs, ref]
        } as Selection

        setActiveSelection(expandedSelection)
        saveSelection(expandedSelection)
    }

    const removeSelection = async (selectionUrl: UrlString) => {
        if (!dataset || !hasResourceInfo(dataset) || !editable) return

        const selectionToRemove = selections.find(otherSelection => otherSelection.url === selectionUrl)
        if (!selectionToRemove) {
            console.log('No selection to remove.')
            return
        }

        if (activeSelection?.url === selectionUrl) setActiveSelection(undefined)

        const sourceUrl = getSourceUrl(dataset)
        let modifiedDataset = removeThing(dataset, selectionToRemove.url)

        // Also remove all the E13s that were associated with this selection
        e13s
            .filter(e13 => e13.target === selectionToRemove.url)
            .forEach(e13 => {
                modifiedDataset = removeThing(dataset, e13.url)
            })

        const savedDataset = await saveSolidDatasetAt(sourceUrl, modifiedDataset, { fetch: session.fetch as any })
        updateDataset(await getSolidDataset(getSourceUrl(savedDataset), { fetch: session.fetch as any }))
        console.log('Selection succesfully removed from your personal dataset')
    }

    const removeFromActiveSelection = (ref: string) => {
        if (!activeSelection) return

        setActiveSelection((activeSelection): Selection | undefined => {
            if (!activeSelection) return
            const newRefs = activeSelection.refs
            newRefs.splice(newRefs.findIndex(r => r === ref), 1)
            return {
                url: activeSelection.url,
                refs: newRefs
            }
        })
        saveSelection(activeSelection)
    }

    return (
        <SelectionContext.Provider value={{
            availableSelections: selections,
            highlightSelection: (targetUrl: UrlString) => {
                const toHighlight = selections.find(selection => selection.url === targetUrl)
                if (!toHighlight) return
                setSecondaryActiveSelection(toHighlight)
                setTimeout(() => {
                    setSecondaryActiveSelection(undefined)
                }, 2000)
            },
            setActiveSelection: (selectionUrl: UrlString) => {
                const toSet = selections.find(selection => selection.url === selectionUrl)
                toSet && setActiveSelection(toSet)
            }
        }}>
            {(activeSelection && active && selectionPanel.current) && 
                createPortal(<SelectionEditor
                    selection={activeSelection}
                    setSelection={setActiveSelection} />, selectionPanel.current)
            }

            {
                hullContainer && [...selections].map(selection => {
                    return (
                        <SelectionHull
                            key={`overlay_${selection.url}`}
                            selection={selection}
                            highlight={selection.url === activeSelection?.url}
                            secondaryHighlight={selection.url === secondaryActiveSelection?.url}
                            setActiveSelection={(selection) => {
                                setActiveSelection(selection)
                                setActiveLayer(asUrl(analysisThing!))
                            }}
                            removeSelection={removeSelection}
                            svgBackground={hullContainer} />
                    )
                })
            }
        </SelectionContext.Provider>
    )
}
